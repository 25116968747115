import { create } from 'zustand';
import { SynthReport } from '@models/synthReport';

export interface SynthReportState {
  synthReport: SynthReport;
  setSynthReport: (synthReport: SynthReport) => void;
}

 const useSynthReportStore = create<SynthReportState>(
  (set): SynthReportState => ({
    synthReport: {
      highlights: [],
      reports: [],
      transcripts: [],
    },
    setSynthReport: async (synthReport: SynthReport): Promise<void> => {
      set({ synthReport: synthReport });
    },
  }),
);

export default useSynthReportStore;