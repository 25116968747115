import { useLocation } from 'react-router-dom';
import Logo from '@assets/Logo.svg';
import ToggleMenu from '@common/Navbar/ToggleMenu';
import LogoutMenu from '@components/LogoutMenu';

const Navbar = (): JSX.Element => {
  const location = useLocation();

  const isPdfViewerPage = location.pathname === '/pdf-viewer';
  return (
    <div className="flex justify-between py-6 px-10 right-0 z-50 bg-[#4A535F] shadow-xl">
      <div className='flex items-center'><img src={Logo} alt="Logo Leonardo" /> <span className="text-white  font-bold italic">| INVESTOR RELATIONS ASSISTANT</span></div>
      <div className="flex justify-between items-center mr-6 gap-8">
        {!isPdfViewerPage && <ToggleMenu />}
        <LogoutMenu />
      </div>
    </div>
  );
};

export default Navbar;
