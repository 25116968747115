
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import './styles.css';

export interface MarkdownViewerProps {
    text: string;
}

const MarkdownViewer = (
    props: MarkdownViewerProps,
): JSX.Element => {
    const { text } = props;

    return (
        <div className="markdown-container">
            <Markdown
                remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
                rehypePlugins={[rehypeRaw]}
            >
                {text}
            </Markdown>
        </div>
    );
};

export default MarkdownViewer;
