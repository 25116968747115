import React, { useEffect } from 'react';
import Section from '@components/Section';
import SectionTextarea from '@components/SectionTextarea';
import Questions from '@components/Questions';
import MetaDataTags from '@components/MetaDataTags'; // Import del componente MetaDataTags
import useSynthReportStore from '@stores/synthReportStore/useSynthReportStore'; // Import dello store SynthReport
import { useQuartersFilter } from '@stores/filters/useQuartersFilters'; // Import dello store Quarters
import { usePeersFilter, useTopicsFilter } from '@stores/filters';
import { Collapse, Divider } from 'antd';
import { Highlight } from '@models/synthReport';
import { useNavigate } from 'react-router-dom';
import { Report } from '@models/synthReport';
import useReportStore from '@stores/reportStore/useReportStore';

const AllSections: React.FC = (): JSX.Element => {
  const selectedPeer = usePeersFilter((state) => state.peer);
  const selectedTopic = useTopicsFilter((state) => state.topic);
  // Recupera il synthReport dallo store Zustand
  const { synthReport } = useSynthReportStore((state) => state);
  const {setReport, clearReport} = useReportStore((state) => state);

  // Recupera il quarter dallo store Zustand
  const { quarter } = useQuartersFilter((state) => state);
  const navigate = useNavigate();

  const noDataMessage =
    'No data available for the selected topic/peer combination.';

    useEffect(()=>{
      clearReport()
    },[])

    const reportViewer = (report: Report) => {
      setReport(report);
      navigate('/pdf-viewer')
    }

    const assistantPage = (): void => {
      navigate('/assistente')
    }

  function SectionHighlights(props: { index: number; highlight: Highlight }): JSX.Element {
    const { index, highlight } = props;
    return (
      <div key={`highlight-${index}`} className="mb-4">
        {/* MetaDataTags per ciascun SectionTextarea */}

        <MetaDataTags
          quarter={quarter ? quarter.name : 'Unknown Quarter'} // Nome del quarter
          files={highlight.documents?.map((doc: { name: string }) => doc.name)} // Lista dei file dal synthReport
          date={highlight?.highlights_date}
        />
        <SectionTextarea
          content={highlight.highlights}
          modalContent={highlight.highlights}
          hasIcons={false}
        />
      </div>
    );
  }

  return (
    <div className="w-full">
      {/* Sezione Highlights */}
      {synthReport.highlights?.length > 0 ? (
        <Section
          title="Highlights"
          results={synthReport.highlights.length}
          hasButton={true}
        >
          {synthReport.highlights.map((highlight, index) =>
            index == 0 ? (
              <>
                <Divider
                  key={`highlight-${index}`}
                  orientation="left"
                  orientationMargin={20}
                  style={{ color: 'rgb(239 68 68)' }}
                >
                  {highlight.title}
                </Divider>
                <SectionHighlights index={index} highlight={highlight} />
              </>
            ) : (
              <Collapse
                className='mb-3'
                items={[
                  {
                    key: `highlight-${index}`,
                    label: (
                      <span style={{ color: 'red', fontWeight: 'bold' }}>
                        {highlight.title}
                      </span>
                    ),
                    children: (
                      <SectionHighlights index={index} highlight={highlight} />
                    ),
                  },
                ]}
              ></Collapse>
            ),
          )}
        </Section>
      ) : (
        <Section title="Highlights" results={0} hasButton={false}>
          <p className="italic font-light text-gray-500 mt-3">
            {noDataMessage}
          </p>
        </Section>
      )}

      {/* Sezione Reports */}
      {selectedPeer &&
        selectedTopic &&
        (synthReport.reports?.length > 0 ? (
          <Section
            title="Reports"
            results={synthReport.reports.length}
            hasButton={true}
          >
            {synthReport.reports.map((report, index) => (
              <div key={`report-${index}`} className="mb-4">
                {/* MetaDataTags per ciascun SectionTextarea */}
                <Divider
                  orientation="left"
                  orientationMargin={20}
                  style={{ color: 'rgb(239 68 68)' }}
                >
                  {report.title}
                </Divider>
                <MetaDataTags
                  quarter={quarter ? quarter.name : 'Unknown Quarter'}
                  files={report.documents?.map((doc) => doc.name)} // Lista dei file associati al report
                  date={report?.extraction_date}
                />
                <SectionTextarea
                  content={report.report}
                  modalContent={report.report}
                  hasIcons={true}
                  viewerCallback={() => reportViewer(report)}
                  assistantCallback={() => assistantPage()}
                />
              </div>
            ))}
          </Section>
        ) : (
          <Section title="Reports" results={0} hasButton={false}>
            <p className="italic font-light text-gray-500 mt-3">
              {noDataMessage}
            </p>
          </Section>
        ))}

      {/* Sezione Questions & Answers */}
      {selectedPeer &&
        selectedTopic &&
        synthReport.transcripts?.length > 0 &&
        (synthReport.transcripts[0].relevant_elements.length > 0 ? (
          <>
            <Questions
              title="Questions & Answers"
              results={synthReport.transcripts[0]?.relevant_elements.length}
              hasButton={false}
              questions={synthReport?.transcripts[0]}
              quarter={quarter ? quarter.name : 'Unknown Quarter'}
              files={synthReport.transcripts[0]?.documents?.map((doc) => doc.name)}
            />
          </>
        ) : (
          <Section title="Questions & Answers" results={0} hasButton={false}>
            <p className="italic font-light text-gray-500 mt-3">
              {noDataMessage}
            </p>
          </Section>
        ))}
    </div>
  );
};

export default AllSections;
