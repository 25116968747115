import useAxios from '@hooks/api/useAxios';
import { AxiosResponse } from 'axios';

type SynthReportService = {
  getSynthReport: (
    companyId: number | null,
    topicId: number | null,
    quarter?: number | null,
  ) => Promise<AxiosResponse>;
  // getSynthReport: (path: string) => Promise<AxiosResponse>;
};

export const useSynthReportService = (): SynthReportService => {
  const api = useAxios();

  const getSynthReport = (
    companyId: number | null,
    topicId: number | null,
    quarter?: number | null,
  ): Promise<AxiosResponse> =>
    api.get(`/api/synth-report`, {
      params: {
        company_id: companyId,
        topic_id: topicId,
        quarter: quarter,
      },
    });
  // const getSynthReport = (path: string): Promise<AxiosResponse> => axios.get(path);

  return {
    getSynthReport,
  };
};
