import { useEffect, useState } from 'react';
// import useQuarterService from '@services/quarterService/useQuarterService';
import { Quarter, useQuartersFilter } from '@stores/filters/useQuartersFilters';
import useTopicsStore from '@stores/topicsStore/useTopicsStore';
import useTopicService from '@services/topicService/useTopicService';
import { useTopicsFilter } from '@stores/filters';
import { Topic } from '@stores/filters/useTopicsFilter';


const FitlersPillContainer = (): JSX.Element => {
  const [quarters, setQuarters] = useState<Quarter[]>([]);
  const [topics, setTopics] = useTopicsStore((state) => [
    state.topics,
    state.setTopics,
  ]);
  // const { getAllQuarters } = useQuarterService();

  const { getAllTopics } = useTopicService();
  const topicFilter = useTopicsFilter((state) => state.topic);
  const setTopicFilter = useTopicsFilter((state) => state.setTopic);
  const quarterFilter = useQuartersFilter((state) => state.quarter);
  const setQuarterFilter = useQuartersFilter((state) => state.setQuarter);


  useEffect(() => {
    const quarterList: Quarter[] = [
      { id: 1, name: "Q1 - 2024", value: 1 },
      { id: 2, name: "Q2 - 2024", value: 2 },
      { id: 3, name: "Q3 - 2024", value: 3 },
      { id: 4, name: "Q4 - 2024", value: 4 }
    ];
    setQuarters(quarterList);
    setQuarterFilter(quarterList[1]);
    console.log(topicFilter);
    // Set the default quarter to the Q2 - 2024
    //  getAllQuarters().then((response) => {
    //   console.log(response.data)
    //   setQuarters(response.data);
    // })
  }, []);
  useEffect(() => {
    getAllTopics().then((response) => {
      // Find the maximum id from the existing topics
      const maxId = Math.max(...response.data.map((topic:Topic) => topic.id));

      // Create 3 new topics with dynamic ids
      const newTopics = [
        { id: maxId + 1, name: 'Long-term guidance/targets', description: 'Long-term guidance/targets' },
        { id: maxId + 2, name: 'Cash Flow Conversion', description: 'Cash Flow Conversion' },
        { id: maxId + 3, name: 'Fixed-price contracts', description: 'Fixed-price contracts' }
      ];

      // Append new topics to the existing ones
      const updatedTopics = [...response.data, ...newTopics];
      setTopics(updatedTopics);
    });

  }, []);




  return (
    <>
      <div className="w-full text-center flex flex-col gap-3 justify-start px-16">
        <h1 className="border-white text-3xl text-white">Select a topic to start the AI engine</h1>
        <hr className="w-1/2 self-center text-center" />

        {/* Topics Section */}
        <div className="flex justify-start text-white pt-6">
          <h3 className="m-1 mx-2 p-1 font-bold">Topic:</h3>
          <div className="flex flex-wrap justify-start">
            {topics.map((topic, i) => {
              const isDisabled = topics.slice(-3).some((t: Topic) => (t.id === topic.id));
              return (
                <div
                  key={i}
                  className={`
                    ${topic.name !== topicFilter?.name && !isDisabled ? "bg-white text-[#39414C] " : ""}
                    ${isDisabled ? " bg-greyNaLdo text-[#39414C] pointer-events-none border-none " : " cursor-pointer "}
                    rounded-full border-2 m-1 mx-2 p-1 px-4 text-[medium]
                  `}
                  onClick={() => {
                    if (!isDisabled) {
                      setTopicFilter(topic);
                    }
                  }}
                >
                  {topic.name}
                </div>
              );
            })}
          </div>
        </div>

        {/* Quarters Section */}
        <div className="flex justify-start text-white">
          <h3 className="m-1 mx-2 p-1 font-bold">Period:</h3>
          <div className="flex flex-wrap justify-start">
            {quarters.map((quarter, i) => {
              const isDisabled = quarter.id !== 2; // Only Q2 is enabled
              return (
                <div
                  key={i}
                  className={`
                    ${quarter !== quarterFilter && !isDisabled ? "bg-white text-[#39414C]" : ""}
                    ${isDisabled ? "bg-greyNaLdo text-[#39414C] pointer-events-none border-none" : "cursor-pointer"}
                    rounded-full flex items-center border-2 m-1 mx-2 p-1 px-4 text-[medium]
                  `}
                  onClick={() => setQuarterFilter(quarter)}
                >
                  {quarter.name}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default FitlersPillContainer;
