import { NavLink, useLocation } from 'react-router-dom';

const ToggleButton = (): JSX.Element => {
  const { pathname } = useLocation();

  return (
    <div className="flex items-center justify-center">
      <div className="flex items-center justify-between w-64 h-9 bg-white rounded-full cursor-pointer relative">
        <NavLink to="/report" className="w-1/2 text-center font-bold">
          Report
        </NavLink>
        <NavLink to="/assistente" className="w-1/2 text-center font-bold">
          Assistant
        </NavLink>
        <div
          className={`absolute h-full w-1/2 bg-redLdo shadow-xl rounded-full transition-transform duration-300 ease-in-out flex items-center justify-center text-white font-bold ${
            pathname === '/report'
              ? 'transform translate-x-0'
              : 'transform translate-x-full'
          }`}
        >
          {pathname === '/report' ? 'Report' : 'Assistant'}
        </div>
      </div>
    </div>
  );
};

export default ToggleButton;
