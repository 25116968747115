import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface Quarter {
  id: number;
  name: string;
  value: number;
}

export interface QuarterState {
  quarter: Quarter | null;
  setQuarter: (quarterToChange: Quarter) => void;
  clearQuarter: () => void;
}

export const useQuartersFilter = create<QuarterState>()(
  persist<QuarterState>(
    (set) => ({
      quarter: null,
      setQuarter: (quarterToChange: Quarter): void => set({ quarter: quarterToChange }),
      clearQuarter: (): void => set({ quarter: null }),
    }),
    {
      name: 'quarter',
      storage: {
        getItem: (name) => {
          const item = localStorage.getItem(name);
          return item ? JSON.parse(item) : null;
        },
        setItem: (name, value) => {
          localStorage.setItem(name, JSON.stringify(value));
        },
        removeItem: (name) => {
          localStorage.removeItem(name);
        },
      },
    }
  )
);