import React from 'react';
import { Tag, Tooltip } from 'antd';
import { getLocaleDate } from '@utils/dateFormatter';

interface MetaDataTagsProps {
  quarter: string;  // Esempio: "2024-Q2"
  files: string[];  // Lista di file
  date?: string
}

const MetaDataTags: React.FC<MetaDataTagsProps> = ({ quarter, files, date }) => {
  // Colore predefinito per il quarter (rosso)
  const quarterColor = 'rgb(239, 68, 68)';

  // Colore predefinito per i file (grigio scuro)
  const fileTagStyle = {
    backgroundColor: 'rgb(155,155,159)',
    color: '#ffffff',
  };

  let dateString: string | null
  (date)? dateString = getLocaleDate(date): dateString = null;

  // Funzione per troncare il nome del file se è troppo lungo
  const truncateFileName = (fileName: string, maxLength: number): string => {
    return fileName.length > maxLength
      ? fileName.slice(0, maxLength) + '...'
      : fileName;
  };

  return (
    <div style={{ display: 'flex', gap: '8px', alignItems: 'center', flexWrap: 'wrap' }}>
      {/* Quarter Tag */}
      <Tag style={{ backgroundColor: quarterColor, color: '#ffffff' }}>
        {quarter}
      </Tag>

      {/* File Tags con Tooltip per nomi lunghi */}
      {files && files.map((file, index) => (
        <Tooltip key={index} title={file}>
          <Tag style={fileTagStyle}>
            {truncateFileName(file, 30)} {/* Tronca il file se è più lungo di 30 caratteri */}
          </Tag>
        </Tooltip>
      ))}      
      {dateString && 
        <Tooltip key={dateString} title={dateString}>
          <Tag style={fileTagStyle}>
            {truncateFileName(dateString, 10)}
          </Tag>
        </Tooltip>
      }
    </div>
  );
};

export default MetaDataTags;
