import SidebarPeerSearchFilter from '@components/SidebarPeerSearchFilter';
import { usePeersFilter, useTopicsFilter } from 'stores/filters';
//import {  useTranscriptFilter, useSynthReportStore, useSynthReportService } from '@services/report';
import { useSynthReportService } from '@services/report';
import AnalysisStatus from '@components/AnalysisStatus';
import AllSections from '@components/AllSections';
import { useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { useQuartersFilter } from '@stores/filters/useQuartersFilters';
import useSynthReportStore from '@stores/synthReportStore/useSynthReportStore';
import FitlersPillContainer from '@components/FitlersPillContainer';
import NewsComponent from '@components/NewsComponent/NewsComponent.tsx';

const Report = (): JSX.Element => {
  const selectedPeer = usePeersFilter((state) => state.peer);
  const selectedDropdown = useTopicsFilter((state) => state.topic);
  const selectedQuarter = useQuartersFilter((state) => state.quarter);

  // const setTranscriptFilter = useTranscriptFilter(
  //   (state) => state.setQuestions,
  // );

  const { getSynthReport } = useSynthReportService();
  const { setSynthReport } = useSynthReportStore();

  useEffect(() => {
    if (selectedDropdown || selectedPeer) {
      getSynthReport(
        selectedPeer ? selectedPeer?.id : null,
        selectedDropdown ? selectedDropdown?.id : null,
        selectedQuarter ? selectedQuarter.value : null,
      ).then((synthReport: AxiosResponse) => {
        setSynthReport(synthReport.data);
      });
    }

    /*     let path: string = '';
    if (selectedDropdown && selectedPeer) {
      path = `/mock-apis/json/synth_report_payload.json`;
    } else if (selectedDropdown) {
      path = `/mock-apis/json/synth_report_payload_topic.json`;
    } else if (selectedPeer) {
      path = `/mock-apis/json/synth_report_payload_company.json`;
    }
    getSynthReport(path).then((synthReport: AxiosResponse) => {
      setSynthReport(synthReport.data);
    }); */

    //setTranscriptFilter('' + selectedPeer, selectedDropdown);
  }, [selectedPeer, selectedDropdown]);

  return (
    <>
      <div className="flex flex-col items-left bg-[#39414C] text-darkBlueLdo p-4 border-solid border-b border-b-black border-slate-300">
        <FitlersPillContainer />
        <div className="mt-5">
          {/* <TopicsQuarterFilter /> */}
        </div>
      </div>
      <div>
        <div className="flex">
          <SidebarPeerSearchFilter />
          <div className="flex-1 p-4 flex flex-col items-start">
            <AnalysisStatus />
            {(selectedPeer || selectedDropdown ) && <AllSections />}
            {(selectedQuarter) && <div className=" w-full text-center mt-20">
              <NewsComponent />
            </div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default Report;
